@font-face {
  font-family: "Brandon";
  src: local(""), url("./fonts/brandon_regular.ttf") format("truetype");
}

@font-face {
  font-family: "Brandon-Bold";
  src: local(""), url("./fonts/brandon_bold.ttf") format("truetype");
}

@font-face {
  font-family: "Brandon-Light";
  src: local(""), url("./fonts/brandon_light.ttf") format("truetype");
}

@font-face {
  font-family: "Brandon-Thin";
  src: local(""), url("./fonts/brandon_thin.ttf") format("truetype");
}
